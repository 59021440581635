import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

import { TOption } from 'src/constants/types'

type TInfoData = {
  specs: TOption[],
  regions: TOption[]
}

const downloadXLSX = async (dataToRender: any, infoData: TInfoData) => {
  if (dataToRender) console.log(dataToRender)

  const preparedData = Object.keys(dataToRender).reduce((acc: string[][], key) => {
    const { total, allowEmails, withPhone, regions } = (dataToRender as any)[key]
    const specName = infoData.specs.find((el: any) => el.value === Number(key))?.label || ''

    acc.push([specName, total, allowEmails, withPhone])
    if (Object.keys(regions)?.length) {
      Object.keys(regions).map((regionId) => {
        const { total, allowEmails, withPhone } = regions[regionId]
        const regionName = infoData.regions.find((el: any) => el.value === Number(regionId))?.label || ''
        acc.push([regionName, total, allowEmails, withPhone])
      })
    }
    return acc
  }, [])

  preparedData.unshift(['', 'Всего юзеров', 'Разрешены рассылки', 'Указан телефон'])

  const ws = XLSX.utils.aoa_to_sheet(preparedData)

  ws['!cols'] = [{ wch: 50 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]

  const workbook = {
    SheetNames: ['Sheet1'],
    Sheets: { Sheet1: ws }
  }

  saveAs(
    new Blob([XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' })], {
      type: 'application/octet-stream'
    }),
    `test.xlsx`
  )

}

export default downloadXLSX