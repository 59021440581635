import { FC } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { ReactComponent as WebinarIcon } from 'src/public/svg/webinar_icon.svg'
import { ReactComponent as LogoutIcon } from 'src/public/svg/logout.svg'

import styles from '../navbar.module.scss'

interface IMenuItem {
  link: string
  text: string
  disabled?: boolean
  page: string
  icon?: string
}

interface IMenuItemText {
  Icon: React.ReactElement
  text: string
}

const MenuItemText: FC<IMenuItemText> = ({ Icon, text }) => {
  return (
    <li className={styles.listElement}>
      {Icon}
      <span className={styles.listElementTxt}>{text}</span>
    </li>
  )
}

const MenuItem: FC<IMenuItem> = ({ link, text, disabled, icon, page }) => {
  const iconSvg = (icon?: string) => {
    if(!icon) return <></>
    switch (icon) {
      case 'stat':
        return <WebinarIcon />
      case 'logout':
        return <LogoutIcon />
      default:
        return <></>
    }
  }
  return (
    <Link
      className={clsx(styles.listItem, {
        [styles.listItemDisable]: disabled,
        [styles.listItemActive]: page.includes(link)
      })}
      to={link}
      key={text}
    >
      <MenuItemText text={text} Icon={iconSvg(icon)} />
    </Link>
  )
}

export { MenuItemText }
export default MenuItem
