import { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import Loader from 'src/components/parts/loader/loader'

import { useAuth } from 'src/hooks/AuthProvider'
import { API_URL } from 'src/constants'

import styles from 'src/styles/stat-page/stat-page.module.scss'

const CommonInfo = () => {
  const { userData } = useAuth()
  const [dataToRender, setDataToRender] = useState({} as { all: number; isAllowEmails: number; phone: number })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      const res = await axios.get(`${API_URL}/api/filter/all`)
      if (res.data) {
        setDataToRender(res.data)
      }
      setIsLoading(false)
    }

    if (!userData?.isHasAccess) return
    getData()
  }, [userData])

  return (
    <>
      <Helmet>
        <title>Общая информация</title>
      </Helmet>
      <WidthLimiter>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className={styles.headerMain}>Всего юзеров: {dataToRender?.all}</div>
            <div className={styles.headerMain}>Разрешена рассылка: {dataToRender?.isAllowEmails}</div>
            <div className={styles.headerMain}>Указан телефон: {dataToRender?.phone}</div>
          </div>
        )}

        <ToastContainer />
      </WidthLimiter>
    </>
  )
}

export default CommonInfo
