import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import Button from 'src/components/parts/button/button'
import Loader from 'src/components/parts/loader/loader'

import { useAuth } from 'src/hooks/AuthProvider'
import { alertError } from 'src/utils/alert'
import downloadXLSX from 'src/utils/download-xlsx'
import { API_URL } from 'src/constants'
import { TOption } from 'src/constants/types'

import styles from 'src/styles/stat-page/stat-page.module.scss'

const StatInfo = () => {
  const { userData } = useAuth()
  const [data, setData] = useState({
    specs: [] as TOption[],
    regions: [] as TOption[]
  })
  const [selectedSpecs, setSelectedSpecs] = useState([] as TOption[])
  const [selectedRegions, setSelectedRegions] = useState([] as TOption[])
  const [dataToRender, setDataToRender] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (name: 'specs' | 'regions') => (selected: any) => {
    if (name === 'specs') setSelectedSpecs(selected)
    if (name === 'regions') setSelectedRegions(selected)
  }

  const getStat = async () => {
    if (isLoading) return
    setIsLoading(true)
    const filters = JSON.stringify({
      specs: selectedSpecs.map((el) => el.value),
      regions: selectedRegions.map((el) => el.value)
    })
    try {
      const res = await axios.get(`${API_URL}/api/filter?filters=${filters}`)
      setDataToRender(res.data)
    } catch (err: any) {
      const errMesage = `${err.response?.data?.error} ${err.response?.data?.message}`
      alertError(errMesage)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const fetchAllData = async () => {
      const [specsResponse, regionsResponse] = await Promise.all([
        axios.get(`${API_URL}/api/list/specialities`),
        axios.get(`${API_URL}/api/list/regions`)
      ])

      if (specsResponse.status === 200 && regionsResponse.status === 200) {
        setData({ specs: specsResponse.data, regions: regionsResponse.data })
      } else {
        console.log('Something went wrong :(')
        // TODO Handle error cases
      }
    }

    fetchAllData()
  }, [])

  useEffect(() => {
    if (!userData?.isHasAccess) return
  }, [userData])

  return (
    <>
      <Helmet>
        <title>Статистика по юзерам</title>
      </Helmet>
      <WidthLimiter>
        <div className={styles.headerMain}>Выбрать юзеров, у которых</div>
        <div>
          <div className={styles.subheader}>специальность: </div>
          <Select
            placeholder="Выберите специальности"
            options={data.specs}
            isMulti={true}
            closeMenuOnSelect={false}
            value={selectedSpecs}
            onChange={handleChange('specs')}
          />
        </div>
        <div>
          <div className={styles.subheader}>регион: </div>
          <Select
            placeholder="Выберите регионы"
            options={data.regions}
            isMulti={true}
            closeMenuOnSelect={false}
            value={selectedRegions}
            onChange={handleChange('regions')}
          />
        </div>
        <Button disabled={isLoading || !selectedSpecs?.length} modificator={styles.btnMargin} onClick={getStat}>
          Посчитать
        </Button>
        {isLoading && <Loader />}
        {Object.keys(dataToRender).map((key) => {
          const { total, allowEmails, withPhone, regions } = (dataToRender as any)[key]
          const specName = data.specs.find((el) => el.value === Number(key))?.label
          return (
            <div key={`spec_${key}`} className={styles.container}>
              <div className={clsx(styles.fullWidth, styles.header)}>{specName}</div>
              <div className={styles.item}>Всего юзеров</div>
              <div className={styles.item}>Разрешены рассылки</div>
              <div className={styles.item}>Указан телефон</div>
              <div className={styles.item}>{total}</div>
              <div className={styles.item}>{allowEmails}</div>
              <div className={styles.item}>{withPhone}</div>
              {Object.keys(regions)?.length ? (
                Object.keys(regions).map((regionId) => {
                  const regionData = regions[regionId]
                  const regionName = data.regions.find((el) => el.value === Number(regionId))?.label
                  return (
                    <React.Fragment key={`region_${regionId}`}>
                      <div className={clsx(styles.fullWidth, styles.subheader)}>{regionName}</div>
                      <div className={styles.item}>{regionData.total}</div>
                      <div className={styles.item}>{regionData.allowEmails}</div>
                      <div className={styles.item}>{regionData.withPhone}</div>
                    </React.Fragment>
                  )
                })
              ) : (
                <></>
              )}
            </div>
          )
        })}
        {Object.keys(dataToRender)?.length ? (
          <div className={styles.btnMargin}>
            <Button onClick={() => downloadXLSX(dataToRender, data)}>Скачать</Button>
          </div>
        ) : (
          <></>
        )}
        <ToastContainer />
      </WidthLimiter>
    </>
  )
}

export default StatInfo
