import { Helmet } from 'react-helmet-async'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'

import styles from 'src/styles/error.module.scss'

const NoAccess = () => {
  return (
    <>
      <Helmet>
        <title>403</title>
      </Helmet>
      <WidthLimiter>
        <div className={styles.container}>
          <header className={styles.wrapper}>
            <h2 className={styles.title}>403</h2>
            <div className={styles.text}>
              <p>У вас не хватает прав для просмотра контента</p>
            </div>
          </header>
        </div>
      </WidthLimiter>
    </>
  )
}

export default NoAccess
