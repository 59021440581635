import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'

import AuthProvider from './hooks/AuthProvider'
import PrivateRoute from './routers/route'

import Home from './pages/home/index'
import StatInfo from './pages/stat/index'
import CommonInfo from './pages/common'
import Auth from './pages/auth/index'
import NoAccess from './pages/noaccess'
import NotFound from './pages/404'
import Navbar from './components/global/navbar/navbar'

import { clientEndpoint } from './constants'

const App = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <AuthProvider>
      <nav>
        <Navbar />
      </nav>
      <Routes>
        <Route path={`${clientEndpoint}/`} element={<Home />}>
          <Route element={<PrivateRoute />}>
            <Route path="stat" element={<Home />}>
              <Route index element={<StatInfo />}></Route>
            </Route>
            <Route path="common" element={<Home />}>
              <Route index element={<CommonInfo />}></Route>
            </Route>
          </Route>
          <Route path="login" element={<Auth />} />
          <Route path="noaccess" element={<NoAccess />} />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
          <Route path={`${clientEndpoint}/`} element={<Navigate to={`${clientEndpoint}/stat`} />} />
        </Route>
      </Routes>
    </AuthProvider>
  )
}

export default App
