import { clientEndpoint } from 'src/constants'

import logo from 'src/public/img/logo.png'

const navBarTopItems = [
  {
    link: `${clientEndpoint}/stat`,
    text: 'Статистика',
    // icon: 'stat'
  },
  {
    link: `${clientEndpoint}/common`,
    text: 'Общая сводка',
    // icon: 'stat'
  }
]

export { navBarTopItems, logo }
